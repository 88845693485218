.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 260px;
  margin: 0 auto;
}

@media (min-width: 880px) {
  .auth {
    max-width: 358px;
      }
}